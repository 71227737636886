import $ from 'jquery';

const revertOptimizedImage = function (img) {
  if (img && img.src && img.src.indexOf('photos-optimized') >= 0) {
    console.error('[optimizer] failed', img.src);
    let reverted_url = img.src
      .replace('photos-optimized', 'photos')
      .replace('cover-', 'cover/');
      // .replace(/\\/debug$/, '')
    console.debug('[optimizer] reverting to unoptimized variant', reverted_url);
    img.src = reverted_url;

    if (img.srcset) {
      let reverted_srcset_url = img.srcset
        .replace('photos-optimized', 'photos')
        .replace('cover-', 'cover/');
        // .replace(/\\/debug$/, '')
      img.srcset = reverted_srcset_url;
    }
  }
};

const catchOptimizedPhotosError = function (element) {
  let src = element && element.currentSrc;
  if (src && src.length) {
    revertOptimizedImage(element);
  }
};

const optimizedOnloadHandler = function (evt) {
  if (evt && evt.type === 'load') {
    console.debug('[optimizer] image loaded', evt);
    let img = evt.target;
    if (img && img.src && img.src.indexOf('photos-optimized') >= 0) {
      let revert = false;
      let threshold = 20;
      if (!img.naturalHeight || img.naturalHeight < threshold) {
        revert = true;
      }

      if (!img.naturalWidth || img.naturalWidth < threshold) {
        revert = true;
      }

      if (revert) {
        console.error(
          '[optimizer] reverting due to natural dimensions',
          img.naturalHeight,
          img.naturalWidth
        );
        revertOptimizedImage(img);
      } else {
        console.debug('[optimizer] Optimized image passed size check', img);
      }
    }
  }
};

let image_error_handler = function (evt) {
  let url = (evt && evt.srcElement && evt.srcElement.currentSrc) || '';
  if (evt && evt.type === 'error' && url.indexOf('photos-optimized') >= 0) {
    catchOptimizedPhotosError(evt.srcElement);
  }
};

const ImageOptimizer = function () {
  let images = document.getElementsByTagName('img') || [];
  console.debug('[optimizer] Attaching to optimized photos', images.length);

  const observer = new MutationObserver((mutationsList) => {
    for (const mutation of mutationsList) {
      if (mutation.type === 'childList') {
        mutation.addedNodes.forEach((node) => {
          if (node.tagName === 'IMG') {
            let src = node.getAttribute('src');
            if (src && src.length && src.indexOf('photos-optimized') >= 0) {
              console.log('NEW IMAGE', node);
            }
          }
        });
      }
    }
  });

  observer.observe(document.body, { childList: true, subtree: true });

  if (window.fi && window.fi.length) {
    let collected = [];
    for (let i = 0; i < window.fi.length; i++) {
      collected.push(window.fi[i]);
    }

    window.fi = [];
    console.log('[Optimizer] found ', collected);

    collected.map(function (c) {
      revertOptimizedImage(c);
    });
  }

  for (let i = 0; i < images.length; i++) {
    let img = images[i];
    let disabled_optimizer_fixes = img.disabled_optimizer_fixes || false;

    if (img && !disabled_optimizer_fixes) {
      img.onerror = image_error_handler;
      if (img.src && img.src.indexOf('photos') >= 0) {
        img.onload = optimizedOnloadHandler;
      }
    }
  }
};

ImageOptimizer.error_handler = image_error_handler;

ImageOptimizer.process = function () {
  if (window.fi && window.fi.length) {
    window.fi.map(function (img) {
      revertOptimizedImage(img);
    });
    window.fi = [];
  }
};

setInterval(function () {
  if (window.fi && window.fi.length > 0) {
    ImageOptimizer.process();
  }
}, 1000);

if (typeof window !== 'undefined' && window) {
  window.ImageOptimizer = ImageOptimizer;
}

export default ImageOptimizer;