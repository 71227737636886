import Config from "../config"
import { jwtDecode } from "jwt-decode"

export default class UserModel {
  constructor(cookieStore) {
    this.jwt = cookieStore ? cookieStore.getCookie(Config.JWT) : null
    this.id = "guest"
    this.first_name = "Guest"
    this.last_name = null
    this.email = null
    this.user_type = null
    this.metadata = {}

    if (this.jwt) {
      this.decodeJWT()
    }
    
    if (!window.user) {
      window.user = this;
    }

    console.log("[user] Welcome")
  }

  decodeJWT() {
    try {
      const decoded = jwtDecode.decode(this.jwt)
      if (decoded) {
        this.id = decoded.id
        this.first_name = decoded.first_name
        this.last_name = decoded.last_name
        this.email = decoded.email
        this.user_type = decoded.user_type
        this.metadata = decoded.metadata
      }
    } catch (error) {
      console.error("[user] Failed to decode JWT:", error)
    }
  }

  name() {
    return this.first_name
  }

  full_name() {
    if (this.first_name && this.last_name) {
      return `${this.first_name} ${this.last_name}`
    } else if (this.first_name) {
      return this.first_name
    } else if (this.last_name) {
      return this.last_name
    } else {
      return null
    }
  }

  email() {
    return this.email
  }
}